// Environment
export const FQDN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:3000'
    : process.env.PREVIEW_ENV === 'true'
    ? process.env.VERCEL_URL
    : 'nailit.unsw.edu.au';

// Mailchimp & Mandrill
export const MANDRILL_API_KEY = process.env.MANDRILL_API_KEY || '';
export const MAILCHIMP_API_KEY = process.env.MAILCHIMP_API_KEY || '';
export const DC = 'us8';
export const TAG_ID = '788409';
export const LIST_ID = process.env.MAILCHIMP_LIST_ID || '';
export const MAILCHIMP_BASE_URL = `https://${DC}.api.mailchimp.com/3.0/lists/${LIST_ID}`;
export const MAILCHIMP_SUBSCRIBER_HASH_KEY = 'mailchimpSubscriberHash';
export const MAILCHIMP_HEADERS = {
  'Content-Type': 'application/json',
  Authorization: `Basic ${Buffer.from('apikey:' + MAILCHIMP_API_KEY).toString(
    'base64'
  )}`,
};

//Google Analytics
export const GOOGLE_ANALYTICS_TRACKING_CODE =
  process.env.GOOGLE_ANALYTICS_TRACKING_CODE || '';

//Google Tag Manager
export const GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '';
export const GOOGLE_TAG_MANAGER_ENV_AUTH =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV_AUTH || '';
export const GOOGLE_TAG_MANAGER_ENV_PREVIEW =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV_PREVIEW || '';

//Tawk.to
export const TAWK_ID = '5e9d239569e9320caac55842';

// Email.
export const EMAIL_FROM_ADDRESS = 'hi@nailit.unsw.edu.au';
export const EMAIL_FROM_NAME = 'UNSW Nail It';
export const EMAIL_SUBJECT = 'Your Nail It Answers';
export const EMAIL_HEADER_URL = `http://${FQDN}/email-header.png`;

// Sentry
export const SENTRY_DSN =
  process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN;
