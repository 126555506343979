import * as Sentry from '@sentry/nextjs';

import { SENTRY_DSN } from './lib/config';

// https://docs.sentry.io/platforms/javascript/configuration/options/
Sentry.init({
  /*
    The DSN tells the SDK where to send the events. If this value is not provided, the SDK will try to read it from the SENTRY_DSN environment variable
  */
  dsn: SENTRY_DSN,
  /*
    Turns debug mode on or off. If debug is enabled SDK will attempt to print out useful debugging information if something goes wrong with sending the event. The default is always false
  */
  debug: process.env.NODE_ENV !== 'production',
  /*
    Sets the environment. This string is freeform and not set by default.
  */
  environment: process.env.NODE_ENV
});