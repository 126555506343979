import { AppProps } from 'next/app';
import Head from 'next/head';
import './app.css';
import React, { useEffect } from 'react';
import { Router } from 'next/router';
import {
  GOOGLE_TAG_MANAGER_ENV_AUTH,
  GOOGLE_TAG_MANAGER_ENV_PREVIEW,
  GOOGLE_TAG_MANAGER_ID,
} from '../lib/config';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

interface MError {
  err: Error;
}

interface ModifiedAppProps extends AppProps, MError {}

function MyApp({ Component, pageProps, err }: ModifiedAppProps) {
  const modifiedPageProps = { ...pageProps, err };

  const gtmParams = {
    id: GOOGLE_TAG_MANAGER_ID,
    environment: {
      gtm_auth: GOOGLE_TAG_MANAGER_ENV_AUTH,
      gtm_preview: GOOGLE_TAG_MANAGER_ENV_PREVIEW,
    },
  };

  useEffect(() => {
    Router.events.on('routeChangeComplete', onRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', onRouteChange);
    };
  }, []);

  const onRouteChange = () => {
    window.scrollTo(0, 0);
  };

  return (
    <GTMProvider state={gtmParams}>
      <Head>
        <title>Nail It! &middot; Your Gateway to UNSW</title>
        <meta name="title" content="Nail It! &middot; Your Gateway to UNSW" />
        <meta
          name="description"
          content="Let's nail your application for an early offer at UNSW."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nailit.unsw.edu.au/" />
        <meta
          property="og:title"
          content="Nail It! &middot; Your Gateway to UNSW"
        />
        <meta
          property="og:description"
          content="Let's nail your application for an early offer at UNSW."
        />
        <meta
          property="og:image"
          content="https://nailit.unsw.edu.au/og-tile.jpg"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nailit.unsw.edu.au/" />
        <meta
          property="twitter:title"
          content="Nail It! &middot; Your Gateway to UNSW"
        />
        <meta
          property="twitter:description"
          content="Let's nail your application for an early offer at UNSW."
        />
        <meta
          property="twitter:image"
          content="https://nailit.unsw.edu.au/og-tile.png"
        />
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width"
          key="viewport"
        />
        <link rel="icon" href="/favicons/favicon-32.png" sizes="32x32" />
        <link rel="icon" href="/favicons/favicon-57.png" sizes="57x57" />
        <link rel="icon" href="/favicons/favicon-76.png" sizes="76x76" />
        <link rel="icon" href="/favicons/favicon-96.png" sizes="96x96" />
        <link rel="icon" href="/favicons/favicon-128.png" sizes="128x128" />
        <link rel="icon" href="/favicons/favicon-192.png" sizes="192x192" />
        <link rel="icon" href="/favicons/favicon-228.png" sizes="228x228" />
        <link
          rel="shortcut icon"
          sizes="196x196"
          href="/favicons/favicon-196.png"
        />
        <link
          rel="apple-touch-icon"
          href="/favicons/favicon-120.png"
          sizes="120x120"
        />
        <link
          rel="apple-touch-icon"
          href="/favicons/favicon-152.png"
          sizes="152x152"
        />
        <link
          rel="apple-touch-icon"
          href="/favicons/favicon-180.png"
          sizes="180x180"
        />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta
          name="msapplication-TileImage"
          content="/favicons/favicon-144.png"
        />
        <meta
          name="msapplication-config"
          content="/favicons/browserconfig.xml"
        />
      </Head>
      <Component {...modifiedPageProps} />
    </GTMProvider>
  );
}

export default MyApp;
